<template>
    <div class="login-view">
        <div class="title">MaxSaving</div>
        <Form class="login-form" @submit="onSubmit">
            <CellGroup inset border>
                <Field
                    v-model="formData.username"
                    label="LDAP账号"
                    :rules="[{ required: true, message: '请填写LDAP账号' }]"
                ></Field>
                <Field
                    v-model="formData.password"
                    label="密码"
                    type="password"
                    :rules="[{ required: true, message: '请填写密码' }]"
                ></Field>
            </CellGroup>
            <div class="submit-panel">
                <Button type="primary" native-type="submit" round block
                    >登录</Button
                >
            </div>
        </Form>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.login-view {
    height: 100vh;
    background-color: #f6f6f6;

    .title {
        color: #fff;
        font-size: 7vw;
        line-height: 8.2vw;
        text-align: center;
        padding: 2.4vw 0;
        background-color: @primary-color;
    }

    .login-form {
        margin-top: 10vw;
    }

    .submit-panel {
        margin-top: 10vw;
        padding: 0 4vw;
    }
}
</style>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    Field,
    CellGroup,
    Form,
    Button,
    showLoadingToast,
    showFailToast,
} from 'vant';

import { userLogin } from '@/common/API';
import { isEmptyArray } from '@/common/tools';
import { useUserStore } from '@/stores/global';

const route = useRoute();
const router = useRouter();
const store = useUserStore();

let formData = ref({
    username: '',
    password: '',
});

let loading = ref(false);

async function onSubmit(values) {
    try {
        loading.value = true;
        showLoadingToast({ message: '登录中', forbidClick: true });
        let res = await userLogin(
            formData.value.username,
            formData.value.password
        );
        loading.value = false;
        if (res.error_no !== 0) {
            showFailToast('用户名或密码错误');
            return;
        }
        store.setCurrentUser({
            username: res.data.userName,
            roles: isEmptyArray(res.data.role_list)
                ? ['guest']
                : res.data.role_list,
        });

        router.replace(route.query.refer || '/?login=1');
    } catch (e) {
        loading.value = false;
        showFailToast('未知异常导致加载失败');
    }
}
</script>
